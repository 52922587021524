import React, { useCallback, useState } from 'react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useMediaQuery } from 'react-responsive'
import THEME from 'utils/theme'
import ArrowIcon from 'icons/Arrow'
import SimpleStoryCard from 'components/SimpleStoryCard'
const Slider = dynamic(() => import('react-slick'), { ssr: false })

const NextArrow = ({ className, style, onClick }) => (
  <button aria-label='next' className={className} style={style} onClick={onClick}>
    <ArrowIcon direction='right' width='auto' height='24px' color={THEME.COLORS.SECONDARY} />
  </button>
)
const PrevArrow = ({ className, style, onClick }) => (
  <button aria-label='previous' className={className} style={style} onClick={onClick}>
    <ArrowIcon direction='left' width='auto' height='24px' color={THEME.COLORS.SECONDARY} />
  </button>
)
const LastSlide = ({ link }) => (
  <div className='col col--item'>
    <div className='lastSlide__container'>
      <Link href={link.href} legacyBehavior>
        <a className='lastSlide'>More Videos</a>
      </Link>
    </div>
    <style jsx>{`
      .col--item {
        flex-basis: 220px;
        min-width: 220px;
        max-width: 220px;
      }
      .lastSlide__container {
        position: relative;
        padding-top: 75%;
      }
      .lastSlide {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000000;
        color: #fff;
        font-family: ${THEME.FONTS.BEBAS};
        font-size: 40px;
        line-height: 1.4;
      }
      @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
        .col--item {
          flex-basis: 100%;
          min-width: 100%;
          max-width: 100%;
        }
      }
    `}</style>
  </div>
)
const StoryCarousel = ({
  title,
  items,
  link,
  showAuthor = false,
  playlist = false,
  handleScrollOnlyOnce = false,
  onScroll,
  onSeeAll,
  onStoryClick
}) => {
  const shouldSlick = useMediaQuery({ query: '(min-width: 768px)' })
  const [scrollHandled, setScrollHandled] = useState(false)
  const handleSeeAll = useCallback(() => {
    if (onSeeAll) onSeeAll()
  }, [onSeeAll])
  const handleScroll = useCallback(() => {
    if (!scrollHandled && onScroll) {
      onScroll()
      if (handleScrollOnlyOnce) setScrollHandled(true)
    }
  }, [handleScrollOnlyOnce, scrollHandled, setScrollHandled, onScroll])
  const settings = {
    className: 'storyCarousel__slider',
    dots: true,
    dotsClass: 'slick-dots storyCarousel__dots',
    infinite: false,
    lazyLoad: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToShow: 4,
    slidesToScroll: 4,
    afterChange: handleScroll
  }
  if (!items || !items.length) return null
  const slides = items.slice(0, 15).map((item) => {
    let _link = null
    if (playlist) {
      _link = {
        href: {
          pathname: link.href,
          query: { video: item.id }
        }
      }
    }
    return (
      <div key={item.id} className='col col--item' onClick={() => onStoryClick?.(item)}>
        <SimpleStoryCard story={item} options={{ showAuthor, showAuthorImage: showAuthor, link: _link }} />
        <style jsx>{`
          .col--item {
            flex-basis: 220px;
            min-width: 220px;
            max-width: 220px;
          }
        `}</style>
      </div>
    )
  })
  return (
    <>
      <div className='storyCarousel'>
        <h2 className='storyCarousel__title'>
          {title}
          {link && (
            <div onClick={handleSeeAll}>
              <Link href={link.href} legacyBehavior>
                <a>See all</a>
              </Link>
            </div>
          )}
        </h2>
        <div className='storyCarousel__body'>
          {shouldSlick ? (
            <Slider {...settings}>
              {slides}
              <LastSlide link={link} key='lastSlide' />
            </Slider>
          ) : (
            <div className='row row--scroll' onScroll={handleScroll}>
              {slides}
              <LastSlide link={link} key='lastSlide' />
            </div>
          )}
        </div>
      </div>
      <style jsx global>{`
        .storyCarousel__slider {
          padding-bottom: 10px;
        }
        .storyCarousel__slider.regular {
          display: flex;
          flex-wrap: nowrap;
          overflow-x: auto;
          justify-content: left;
          padding-bottom: 0;
          margin-left: -8px;
          margin-right: -8px;
        }
        .storyCarousel .slick-arrow {
          top: 40%;
          width: 40px;
        }
        .storyCarousel .slick-arrow.slick-disabled {
          opacity: 0;
        }
        .storyCarousel .storyCarousel__dots {
          margin: 0;
          padding: 0;
          text-align: right;
          bottom: 0px;
          right: 15px;
        }
        .storyCarousel .storyCarousel__dots li {
          width: 5px;
          height: 5px;
          margin: 0 5px;
        }
        .storyCarousel .storyCarousel__dots li button {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          margin: 0;
          padding: 0;
          background: #d8d8d8;
        }
        .storyCarousel .storyCarousel__dots li.slick-active button {
          background: ${THEME.COLORS.SECONDARY};
        }
        .storyCarousel .storyCarousel__dots li button:before {
          content: none;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .storyCarousel__slider .col--item {
            flex-basis: 100% !important;
            min-width: 100% !important;
            max-width: 100% !important;
          }
        }
      `}</style>
      <style jsx>{`
        .storyCarousel + .storyCarousel {
          margin-top: 16px;
        }
        .row {
          margin-left: -8px;
          margin-right: -8px;
        }
        .col--item {
          flex-basis: 220px;
          min-width: 220px;
          max-width: 220px;
        }
        .storyCarousel__title {
          font-family: ${THEME.FONTS.BEBAS};
          text-transform: uppercase;
          padding: 12px 0 0 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 25px;
          font-weight: normal;
          line-height: 1.2;
          margin: 0;
        }
        .storyCarousel__title a {
          font-family: ${THEME.FONTS.HELVETICA};
          font-size: 14px;
          color: ${THEME.COLORS.LINKCOLOR};
          text-transform: none;
          font-weight: normal;
          white-space: nowrap;
          margin-left: 5px;
        }
        .storyCarousel__body {
          padding-top: 12px;
          padding-bottom: 12px;
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .storyCarousel__title {
            font-size: 30px;
          }
          .storyCarousel__body {
            padding-left: 0;
            padding-right: 0;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .storyCarousel__title {
            padding-left: 0px;
          }
          .row {
            margin-left: -15px;
            margin-right: -15px;
          }
          .col--item {
            flex-basis: 100%;
            min-width: 100%;
            max-width: 100%;
          }
          .storyCarousel__body {
            margin-left: -15px;
            margin-right: -15px;
          }
        }
      `}</style>
    </>
  )
}

export default StoryCarousel
