import { observable, flow } from 'mobx'
import { storiesApi, playlistsApi } from 'api'
import { CommonStore } from 'stores/common'

export const VideosStore = () => {
  const findFeaturedVideo = flow(function* (id) {
    const result = yield this.api.findById(id)
    this.featuredVideo = result
    return result
  })

  const findFeeds = flow(function* (items, { append = false } = {}) {
    const promises = items.map(async (item) => this.playlistsApi.findById(item.id, { limit: 7 }))
    const videoFeeds = yield Promise.all(promises)
    this.feeds = append ? [...this.feeds, ...videoFeeds] : videoFeeds
    return videoFeeds
  })

  return observable({
    ...CommonStore,
    api: storiesApi,
    storiesApi,
    playlistsApi,
    feeds: [],
    featuredVideo: null,
    findFeeds,
    findFeaturedVideo
  })
}

VideosStore.cacheKey = 'VideosStore'

export default VideosStore
