import React from 'react'
import { observer } from 'mobx-react-lite'
import { ConfigStore } from 'stores/config'
import { useStore } from 'hooks'
import THEME from 'utils/theme'

import MediaQuery from 'components/MediaQuery'

export const LOCATIONS = Object.freeze({
  VIDEOS_INDEX: 'videos-index',
  VIEW_PAGE_TOP_BANNER: 'view-page-banner',
  VIEW_PAGE_SIDE_BOX: 'view-page-side-box'
})

function RnrConfigAd({ location = LOCATIONS.VIEW_PAGE_SIDE_BOX }) {
  const configStore = useStore(ConfigStore)

  const viewPageMobileBanner = configStore.current?.videos?.ads?.view_page_banner_ad_mobile
  const viewPageDesktopBanner = configStore.current?.videos?.ads?.view_page_banner_ad_desktop
  const videosIndexBanner = configStore.current?.videos?.ads?.index_page_banner_ad
  const viewPageSideBox = configStore.current?.videos?.ads?.view_page_list_ad

  return (
    <>
      {(() => {
        switch (location) {
          case LOCATIONS.VIDEOS_INDEX:
            return (
              <>
                {videosIndexBanner && (
                  <div className='video-index-banner-container'>
                    <a href={videosIndexBanner.destination} target='_blank' rel='noopener noreferrer'>
                      <div className='video-index-banner-image' />
                    </a>
                  </div>
                )}
              </>
            )
          case LOCATIONS.VIEW_PAGE_TOP_BANNER:
            return (
              <>
                {viewPageMobileBanner && (
                  <MediaQuery maxWidth={THEME.BREAKPOINTS.SM - 1}>
                    <div className='view-page-banner-container'>
                      <a href={viewPageMobileBanner.destination} target='_blank' rel='noopener noreferrer'>
                        <div className='view-page-banner-image' />
                      </a>
                    </div>
                  </MediaQuery>
                )}
                {viewPageDesktopBanner && (
                  <MediaQuery minWidth={THEME.BREAKPOINTS.SM}>
                    <div className='view-page-banner-container'>
                      <a href={viewPageDesktopBanner.destination} target='_blank' rel='noopener noreferrer'>
                        <div className='view-page-banner-image' />
                      </a>
                    </div>
                  </MediaQuery>
                )}
              </>
            )
          case LOCATIONS.VIEW_PAGE_SIDE_BOX:
            return (
              <>
                {viewPageSideBox && (
                  <div className='view-side-box-container'>
                    <a href={viewPageSideBox.destination} target='_blank' rel='noopener noreferrer'>
                      <div className='view-side-box-image' />
                    </a>
                  </div>
                )}
              </>
            )
          default:
            return null
        }
      })()}
      <style jsx>{`
        .video-index-banner-container {
          width: 100%;
          box-sizing: border-box;
          max-width: 728px;
          padding: 1rem 0;
          margin: 0 auto;
        }
        .video-index-banner-image {
          width: 100%;
          padding-bottom: 12.36%;
          background-image: ${videosIndexBanner ? `url(${videosIndexBanner.src})` : 'none'};
          background-position: center top;
          background-repeat: no-repeat;
          background-size: contain;
        }
        .view-side-box-container {
          display: none;
        }
        .view-side-box-image {
          width: 100%;
          padding-bottom: 83.33%;
          background-image: ${viewPageSideBox ? `url(${viewPageSideBox.src})` : 'none'};
          background-position: center top;
          background-repeat: no-repeat;
          background-size: contain;
        }
        .view-page-banner-container {
          width: 100%;
          max-width: 320px;
          margin: 26px auto;
        }
        .view-page-banner-image {
          width: 100%;
          padding-bottom: 15.62%;
          background-image: ${viewPageMobileBanner ? `url(${viewPageMobileBanner.src})` : 'none'};
          background-position: center top;
          background-repeat: no-repeat;
          background-size: contain;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .view-side-box-container {
            display: block;
            width: 100%;
            max-width: 300px;
            margin: 0 auto;
            padding: 0;
          }
          .view-page-banner-container {
            max-width: 728px;
          }
          .view-page-banner-image {
            padding-bottom: 12.36%;
            background-image: ${viewPageDesktopBanner ? `url(${viewPageDesktopBanner.src})` : 'none'};
          }
        }
      `}</style>
    </>
  )
}

export default observer(RnrConfigAd)
