import React from 'react'
import THEME from 'utils/theme'
import { truncateString } from 'utils/text'
import { videosPageEvents } from 'lib/ga'
import VideoPlayerLink from 'components/VideoPlayerLink'

const FeaturedVideo = ({ items, live = false, videosPage = false }) => {
  if (!items || !items.length) return null
  const item = items[0]
  const handleHeroClick = () => {
    if (videosPage) {
      videosPageEvents.heroClicked(item.url)
    }
  }
  return (
    <section className={`featuredVideo ${live ? 'featuredVideo--live' : ''}`}>
      <div className='container'>
        <div className='row'>
          <div className='col col--left'>
            <div className='featuredVideo__content'>
              <h2 className='featuredVideo__title'>{truncateString(item.title, 100)}</h2>
            </div>
          </div>
          <div className='col col--right'>
            <div className='featuredVideo__video' onClick={handleHeroClick}>
              <VideoPlayerLink story={item} linkOverride={{ href: `/video/${item.id}/${item.slug}` }} />
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .col {
          padding-left: 0;
          padding-right: 0;
        }
        .col--left,
        .col--right {
          min-width: 100%;
          flex-basis: 100%;
        }
        .col--left {
          order: 1;
        }
        .col--right {
          order: 0;
        }
        .featuredVideo {
          background: linear-gradient(180deg, #000 0, #393939);
        }
        .featuredVideo__content {
          display: flex;
          height: 100%;
          flex-direction: column;
          justify-content: center;
          padding: 1rem 0;
        }
        .featuredVideo__label,
        .featuredVideo__title {
          font-family: ${THEME.FONTS.HELVETICA};
          font-weight: 700;
          padding-left: 15px;
        }
        .featuredVideo__label {
          font-size: 16px;
          color: #999;
          letter-spacing: 0.7px;
          text-transform: uppercase;
          margin: 0;
        }
        .featuredVideo--gold .featuredVideo__label {
          color: ${THEME.COLORS.GOLD};
        }
        .featuredVideo--live .featuredVideo__label {
          color: ${THEME.COLORS.PRIMARY};
          margin-bottom: 10px;
        }
        .featuredVideo__title {
          font-size: 24px;
          color: #fff;
          margin: 0;
          letter-spacing: -0.95px;
          text-transform: none;
        }
        .btn--primary {
          display: none;
        }
        .btn--primary,
        .btn--primary:hover {
          background-color: ${THEME.COLORS.GREEN};
        }
        @media (max-width: ${THEME.BREAKPOINTS.SM - 1}px) {
          .featuredVideo {
            margin-top: 0px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .featuredVideo__video {
            margin: 0;
          }
          .featuredVideo__title {
            padding-left: 23px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .col {
            padding-left: 15px;
            padding-right: 15px;
          }
          .col--left {
            order: 0;
            min-width: calc(100% / 3);
            flex-basis: calc(100% / 3);
          }
          .col--right {
            order: 1;
            min-width: calc(100% / 1.5);
            flex-basis: calc(100% / 1.5);
          }
          .featuredVideo {
            margin-bottom: 32px;
          }
          .featuredVideo__content {
            padding: 0 0 32px;
          }
          .featuredVideo__title {
            font-size: 36px;
            padding-left: 0;
          }
          .featuredVideo__video {
            box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);
            position: relative;
            bottom: -2rem;
          }
          .btn--primary {
            align-self: flex-start;
            margin-top: 15px;
            padding: 10px 50px;
            display: inline-block;
            width: auto;
          }
        }
      `}</style>
    </section>
  )
}

export default FeaturedVideo
