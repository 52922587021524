import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from 'hooks'
import THEME from 'utils/theme'
import { videosPageEvents } from 'lib/ga'
import { ConfigStore } from 'stores/config'
import { VideosStore } from 'stores/videos'
import FeaturedVideo from 'components/FeaturedVideo'
import LazyLoad from 'components/LazyLoad'
import StoryCarousel from 'components/StoryCarousel'
import EventDetailActionPanel from 'components/EventDetailActionPanel'
import RnrConfigAd, { LOCATIONS } from 'components/RnrConfigAd'
import InfiniteScroll from 'components/InfiniteScroll'
import LoadingContainer from 'components/LoadingContainer'

const LazyFeedCarousel = React.memo(({ feed }) => {
  let link = { href: `/playlist/${feed.id}/${feed.slug}` }
  const listName = feed.title
  return (
    <>
      <section className='videosIndex__section videosIndex__section--feed'>
        <div className='container'>
          <LazyLoad offset={400} once>
            <StoryCarousel
              items={feed.stories}
              title={feed.title}
              link={link}
              playlist={true}
              onSeeAll={() => videosPageEvents.playlist(listName, 'See all link')}
              onStoryClick={({ url }) => videosPageEvents.playlist(listName, url)}
              onScroll={() => videosPageEvents.playlist(listName, 'Scroll')}
              handleScrollOnlyOnce
            />
          </LazyLoad>
        </div>
      </section>
      <div className='container'>
        <div className='videosIndex__divider' />
      </div>
    </>
  )
})

const VideosIndex = ({ event, forceWinterized = false }) => {
  const configStore = useStore(ConfigStore)
  const videosStore = useStore(VideosStore)
  const aboutRnr = configStore.current?.videos?.about
  const featuredEventImageUrl = configStore.current?.videos?.featured_event_image
  const configVideoFeeds = configStore.current?.videos?.feeds ?? []
  const loader = () => <LoadingContainer isLoading={true} />
  const handleLoadMoreFeeds = async () => {
    const nextFeedItems = configVideoFeeds.slice(videosStore.feeds.length, videosStore.feeds.length + 5)
    await videosStore.findFeeds(nextFeedItems, { append: true })
  }
  return (
    <>
      <div className='videosIndex' data-testid='VideoHome'>
        <div className='background-filler'>
          <RnrConfigAd location={LOCATIONS.VIDEOS_INDEX} />
        </div>
        {!forceWinterized &&
        (event?.status === 'upcoming' || event?.status === 'live' || event?.status === 'on_demand') ? (
          <section className='hero event-countdown-hero'>
            <div className='event-countdown-content'>
              {featuredEventImageUrl && (
                <div className='event-hero-image'>
                  <img src={featuredEventImageUrl} />
                </div>
              )}
              <div className='event-action-panel'>
                <EventDetailActionPanel event={event} linkToCurrentEvent={false} videosPage />
              </div>
            </div>
          </section>
        ) : (
          <FeaturedVideo event={event} items={[videosStore.featuredVideo]} videosPage />
        )}
        {aboutRnr && (
          <>
            <section className='videosIndex__section'>
              <div className='container'>
                <h2 className='about-rnr-title'>About RNR</h2>
                <p className='about-rnr'>{aboutRnr}</p>
              </div>
            </section>
            <div className='container'>
              <div className='videosIndex__divider' />
            </div>
          </>
        )}
        <InfiniteScroll
          hasMore={videosStore.feeds.length < configVideoFeeds.length}
          next={handleLoadMoreFeeds}
          loader={loader}
        >
          {videosStore.feeds.map((feed, idx) => (
            <LazyFeedCarousel feed={feed} key={idx} />
          ))}
        </InfiniteScroll>
        <div className='bottom-spacer' />
      </div>
      <style global jsx>{`
        .background-filler {
          background: #000000;
        }
        .event-countdown-hero {
          display: flex;
          justify-content: center;
          width: 100%;
          padding: 0 0 1rem 0;
          box-sizing: border-box;
          background: linear-gradient(180deg, #000 0, #393939);
          margin-bottom: 30px;
        }
        .event-countdown-content {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 0 2rem;
        }
        .event-hero-image {
          width: 100%;
          height: auto;
          text-align: center;
        }
        .event-hero-image img {
          width: 100%;
          height: auto;
          max-width: 320px;
          margin: 0 auto;
        }
        .event-action-panel {
          max-width: 500px;
          margin-bottom: -44px !important;
          margin: 0 auto;
          position: static;
        }
        @media (max-width: ${THEME.BREAKPOINTS.XS}px) {
          .container {
            padding-left: 8px;
            padding-right: 8px;
          }
        }
        .bottom-spacer {
          padding-bottom: 150px;
        }
        .liveVideos {
          background: linear-gradient(180deg, #000 0, #393939);
        }
        .videosIndex {
          background-color: #fff;
        }
        .videosIndex__section {
          padding-top: 15px;
          padding-bottom: 15px;
        }
        .videosIndex__section--feed {
          min-height: 330px;
        }
        .videosIndex__divider {
          height: 1px;
          width: 100%;
          background-color: #e5e5e5;
        }
        .videosIndex__section--gray {
          background: #f5f5f5;
        }
        .featuredShows__title {
          font-family: ${THEME.FONTS.BEBAS};
          text-transform: uppercase;
          padding: 12px 0 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 25px;
          font-weight: 600;
          line-height: 1.2;
          margin: 0;
        }
        .featuredShows__title a {
          font-family: ${THEME.FONTS.HELVETICA};
          font-size: 14px;
          color: ${THEME.COLORS.LINKCOLOR};
          text-transform: none;
          font-weight: normal;
        }
        .featuredShows__body {
          padding-top: 12px;
          padding-bottom: 12px;
        }
        .featuredShows__body .row {
          flex-wrap: nowrap;
          overflow-x: auto;
          justify-content: left;
          margin-left: -8px;
          margin-right: -8px;
        }
        .featuredShows__body .col--item {
          flex-basis: 220px;
          min-width: 220px;
          max-width: 220px;
        }
        .featuredShows__body .col--item a {
          display: block;
        }
        .featuredShows__body .col--item a img {
          width: 100%;
        }
        .about-rnr-title {
          font-family: ${THEME.FONTS.BEBAS};
          text-transform: uppercase;
          font-size: 25px;
          line-height: 1.2;
          margin: 0;
          padding-top: 28px;
          padding-left: 8px;
        }
        .about-rnr {
          padding-left: 8px;
          font-size: 16px;
          line-height: 150%;
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .featuredShows__title {
            font-size: 30px;
          }
          .featuredShows__title,
          .featuredShows__body {
            padding-left: 0;
            padding-right: 0;
          }
          .about-rnr-title {
            font-size: 30px;
            padding-left: 0;
          }
          .about-rnr {
            padding-left: 0;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .videosIndex {
            margin-bottom: -100px;
          }
          .liveVideos {
            margin-top: -20px;
          }
          .videosIndex__section {
            padding-top: 30px;
            padding-bottom: 30px;
          }
          .videosIndex__section--feed {
            min-height: 420px;
          }
          .featuredShows__body .row {
            flex-wrap: wrap;
            margin-left: -15px;
            margin-right: -15px;
          }
          .featuredShows__body .col--item {
            flex-basis: 25%;
            min-width: 25%;
            max-width: 25%;
          }
          .featuredShows__body .col--item a {
            margin-bottom: 15px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .event-countdown-hero {
            margin-bottom: 0;
          }
          .event-countdown-content {
            flex-direction: row;
            max-width: 1100px;
            align-items: center;
          }
          .event-hero-image {
            width: auto;
          }
          .event-hero-image img {
            max-width: 500px;
          }
          .event-action-panel {
            min-width: 425px;
            margin-left: auto;
          }
        }
      `}</style>
    </>
  )
}

export default observer(VideosIndex)
