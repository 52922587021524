import React from 'react'

export default function PlayButtonIcon({ color = '#FFFFFF', width = '18px', height = '18px' }) {
  return (
    <svg
      viewBox='0 0 14.68 19.92'
      aria-labelledby='play-button'
      className='svgIcon iconPlayButton'
      style={{ width, height, fill: color }}
    >
      <title id='play-button'>Play Video</title>
      <polygon points='0 0 14.69 9.96 0 19.92 0 0' />
    </svg>
  )
}
