import React from 'react'
import Head from 'components/Head'
import SiteLayout from 'components/SiteLayout'
import VideosIndex from 'components/VideosIndex'

import { EventsStore } from 'stores/events'
import { ConfigStore } from 'stores/config'
import { VideosStore } from 'stores/videos'
import { useStore, usePageView } from 'hooks'
import { getServerSideWrapper } from 'utils/data-fetching'

const IndexPage = ({ events = [], feeds = [], featuredVideo, config }) => {
  const event = events?.[0]
  useStore(ConfigStore, { current: config })
  useStore(EventsStore, { items: events, current: event })
  useStore(VideosStore, { feeds, featuredVideo })
  usePageView()

  return (
    <>
      <SiteLayout showNavBarCTA useAvailableEventLogo={false}>
        <Head
          title="Rough N' Rowdy | RnR"
          description="Rough N' Rowdy Brawl is fighters with no defense throwing haymakers. It's the funniest most action packed night you'll ever have. Join us for our next event."
          event={event}
        />
        <VideosIndex event={event} forceWinterized={process.env.WINTERIZED} />
      </SiteLayout>
    </>
  )
}

export const getServerSideProps = getServerSideWrapper(async () => {
  const [events, config] = await Promise.all([EventsStore().findRNR(), ConfigStore().findById('rnr-web')])
  const [feeds, featuredVideo] = await Promise.all([
    VideosStore().findFeeds(config.videos.feeds?.slice(0, 5)),
    VideosStore().findFeaturedVideo(config.videos.featured_story_id)
  ])
  return {
    events,
    feeds,
    featuredVideo,
    config
  }
})

export default IndexPage
