import React from 'react'
import Link from 'next/link'
import { applyFastlyParams } from 'utils/image'
import { storyLink } from 'utils/text'
import PlayButtonIcon from 'icons/PlayButton'

const VideoPlayerLink = ({ story, linkOverride }) => {
  if (!story) return null
  const image =
    story.post_type_meta.barstool_original && story.post_type_meta.barstool_original.image
      ? story.post_type_meta.barstool_original.image
      : story.thumbnail.raw_featured
  const imageSrc = applyFastlyParams(image, { crop: '16:9' })
  const linkOptions = linkOverride || storyLink(story)
  return (
    <>
      <Link {...linkOptions} legacyBehavior>
        <a className='videoPlayerLink'>
          <span className='videoPlayerLink__play'>
            <span>
              <PlayButtonIcon width='35px' height='auto' />
            </span>
          </span>
          <img className='videoPlayerLink__image' src={imageSrc} alt={story.title} />
        </a>
      </Link>
      <style jsx>{`
        .videoPlayerLink {
          display: block;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          width: 100%;
          padding-top: 56.25%;
        }
        .videoPlayerLink__image {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
        .videoPlayerLink__play {
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 110px;
          height: 110px;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.45);
          border: 4px solid #fff;
          z-index: 1;
        }
        .videoPlayerLink__play span {
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 38px;
        }
      `}</style>
    </>
  )
}

export default VideoPlayerLink
